import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { twMerge } from 'tailwind-merge';
import type { HotelDiningMenu, HotelRestaurant, Maybe } from '@dx-ui/gql-types';
import { BrandLink } from '../brand-buttons/brand-buttons';
import { generateUrl, restaurantNameToUrl } from '../../helpers/utils/client';
import type { TFunction } from 'i18next';

export type OnSiteRestaurants = Pick<HotelRestaurant, 'link' | 'name' | 'onSite' | 'description'> &
  RestaurantWithMenu;

type TDiningButtons = {
  className?: string;
  hideExternalLink?: boolean;
  onSiteRestaurants: Array<OnSiteRestaurants>;
};

export type RestaurantWithMenu = {
  diningMenus_noTx?: Maybe<Maybe<Pick<HotelDiningMenu, 'name'>>[]> | null;
  name_noTx?: HotelRestaurant['name'];
};

export const getDiningButtonsProps = (
  onSiteRestaurants: Array<OnSiteRestaurants>,
  relativeUrl: string,
  t: TFunction<['dining']>
) => {
  if (!onSiteRestaurants.length)
    return {
      primary: undefined,
      secondary: undefined,
    };

  if (onSiteRestaurants.length === 1) {
    const restaurant = onSiteRestaurants[0];
    const restaurantUrl = `${relativeUrl}${restaurantNameToUrl(restaurant?.name_noTx || '', true)}`;
    const primary = !!restaurant?.diningMenus_noTx?.length
      ? {
          adaDescription:
            restaurant.diningMenus_noTx.length > 1
              ? t('viewMenusFor', { restaurantName: restaurant.name_noTx })
              : t('viewMenuFor', { restaurantName: restaurant.name_noTx }),
          label: restaurant?.diningMenus_noTx?.length > 1 ? t('viewMenus') : t('viewMenu'),
          url: restaurantUrl,
        }
      : undefined;

    const secondary =
      restaurant && restaurant.link?.url && restaurant.link?.label
        ? {
            adaDescription: restaurant.link.adaDescription || '',
            label: restaurant.link.label,
            url: restaurant.link.url,
          }
        : undefined;

    return {
      primary,
      secondary,
    };
  }

  if (onSiteRestaurants.length > 1) {
    return {
      primary: {
        adaDescription: t('exploreDining'),
        label: t('exploreDining'),
        url: relativeUrl,
      },
    };
  }
};

/**
 * A 'batteries included' reusable dining component with all logic built in to display dining button(s) based on the available data.
 * Two different ways to render:
 * Show a single button to /dining page if multiple onsite restaurants exist AND dining page is present for property.
 * OR if property only has one onsite dining AND (if available) a menu page for that restaurant, render CTA to that menu page AND (if available) show link to any external URL, i.e. to reservation page.
 * @param {Array} props.onSiteRestaurants - Pass in a filtered array of restaurants.onSite === true.
 */
const DiningButtons = ({
  className = '',
  hideExternalLink = false,
  onSiteRestaurants,
}: TDiningButtons) => {
  const { t } = useTranslation('dining');
  const { relativeUrl } = generateUrl(useRouter(), 'dining');
  const button = getDiningButtonsProps(onSiteRestaurants, relativeUrl, t);

  if (button?.primary || button?.secondary)
    return (
      <div
        className={twMerge(
          !!button?.primary && !!button?.secondary && 'flex flex-col gap-3',
          className
        )}
      >
        {button?.primary ? (
          <BrandLink
            adaDescription={button.primary.adaDescription}
            url={button.primary.url}
            showNewWindowIcon={false}
            label={button.primary.label}
          />
        ) : null}
        {!hideExternalLink && button?.secondary ? (
          <BrandLink
            adaDescription={button.secondary.adaDescription}
            url={button.secondary.url}
            showNewWindowIcon={false}
            label={button.secondary.label}
            isNewWindow
          />
        ) : null}
      </div>
    );

  return null;
};

export default DiningButtons;
