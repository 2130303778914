import { useRef } from 'react';
import type { ReactNode } from 'react';
import { getHotelImageVariant, getConsolidatedHoursOfOperation } from '../../helpers/utils/client';
import cx from 'classnames';
import { useRect } from '@dx-ui/utilities-use-rect';
import type { HotelImage, HotelImageVariant } from '@dx-ui/gql-types';
import { useRouter } from 'next/router';
import { ConsolidatedHoursSection } from '../restaurant-hours/ConsolidatedHours';
import { Accordion } from '@dx-ui/osc-accordion';
import { Image } from '../image/Image';
import { useTranslation } from 'next-i18next';
import type { GetHotelHomePageQuery } from '@dx-ui/queries-dx-property-ui';
import { useTheme } from '../../hooks/use-theme';

export type TTabbedOverviewContent = {
  id: 'dining' | 'golf' | 'spa';
  image?:
    | (Pick<HotelImage, 'altText'> & {
        variants: Pick<HotelImageVariant, 'size' | 'url'>[];
      })
    | null;
  description?: string;
  hoursOfOperation?:
    | NonNullable<
        NonNullable<GetHotelHomePageQuery['hotel']>['restaurantOverview']
      >['restaurants'][number]['hoursOfOperation']
    | null;
  path?: string;
  restaurantName?: string;
  isHeadlineFont?: boolean;
  children?: ReactNode;
};

const TabbedOverviewContent = ({
  id,
  image,
  description,
  hoursOfOperation,
  isHeadlineFont,
  restaurantName,
  children,
}: TTabbedOverviewContent) => {
  const { locale = 'en' } = useRouter();
  const { t } = useTranslation(['common', 'dining']);
  const { theme } = useTheme();
  const heading = restaurantName ?? t(`common:${id}`);
  const ref = useRef<React.ElementRef<'div'>>(null);
  const rect = useRect({ ref });
  const variant = image && image.variants && getHotelImageVariant(image.variants, rect?.width ?? 0);
  const consolidatedHours =
    !!hoursOfOperation && getConsolidatedHoursOfOperation({ hoursOfOperation, locale });

  return (
    <div className={cx('grid', { 'md:grid-cols-2': !!image })}>
      {variant && variant?.url ? (
        <div className="flex w-full min-w-0 items-center" ref={ref}>
          <Image
            alt={image.altText ?? ''}
            src={variant.url || ''}
            width={rect?.width ?? 0}
            aspectRatio="3:2"
            className="image-corner-radius"
          />
        </div>
      ) : null}
      <div className={cx('flex flex-col justify-center', { 'items-center': !variant })}>
        <div className={cx('md:px-10', { 'max-w-3xl text-center': !variant })}>
          <h2
            className={cx('mb-2 mt-6', {
              'heading-2xl lg:heading-3xl': isHeadlineFont,
              'accent-heading': isHeadlineFont && theme.accent?.heading,
              'font-bold text-2xl lg:text-3xl': !isHeadlineFont,
            })}
          >
            {heading}
          </h2>
          <p>{description}</p>
          {consolidatedHours ? (
            <div
              className={cx('flex', {
                'justify-center': !variant,
              })}
            >
              <div className="flex w-full flex-col pt-8">
                <Accordion
                  className={cx('accordion-single text-accent text-lg font-bold no-underline', {
                    'self-start': !!variant,
                    'self-center': !variant,
                  })}
                  items={[
                    {
                      key: 'hours',
                      content: (
                        <section className="pt-4">
                          <ConsolidatedHoursSection
                            consolidatedHours={consolidatedHours}
                            showHeader={false}
                          />
                        </section>
                      ),
                      collapsedButtonLabel: t('dining:hours'),
                      collapsedButtonAccessibleLabel: t('dining:showHoursFor', { heading }),
                      expandedButtonLabel: t('dining:hours'),
                      expandedButtonAccessibleLabel: t('dining:hideHoursFor', { heading }),
                    },
                  ]}
                  data-testid="accordion-button"
                  type="multiple"
                />
              </div>
            </div>
          ) : null}
          {children}
        </div>
      </div>
    </div>
  );
};

export default TabbedOverviewContent;
